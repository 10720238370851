<template>
  <div class="promotionCenter">
    <div class="pc-block">
      <div class="title">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.0142 6.12858C22.2856 6.12858 25.4428 4.64286 25.9999 3.15715C25.9999 1.3 22.2856 0 18.0142 0C13.5571 0 9.84277 1.3 9.84277 3.15715C10.3999 4.64286 13.5571 6.12858 18.0142 6.12858Z"
            fill="white"
          />
          <path
            d="M18.0144 18.2001C17.4572 18.2001 16.7144 18.2001 16.1572 18.0144V22.1001C16.7144 22.1001 17.4572 22.1001 18.0144 22.1001C22.4715 22.1001 26.0001 20.9858 26.0001 19.5001V16.1572C24.1429 17.4572 21.3572 18.2001 18.0144 18.2001Z"
            fill="white"
          />
          <path
            d="M18.0144 12.8143C17.4572 12.8143 16.7144 12.8143 16.1572 12.6286V16.7143C16.7144 16.7143 17.4572 16.9001 18.0144 16.9001C22.2858 16.9001 25.4429 15.4143 26.0001 13.9286V10.7715C24.1429 12.0715 21.3572 12.8143 18.0144 12.8143Z"
            fill="white"
          />
          <path
            d="M18.0142 7.4286C14.4856 7.4286 11.6999 6.50003 9.84277 5.38574V7.4286C13.5571 7.80003 16.8999 9.10003 17.0856 11.5143C17.2714 11.5143 17.6428 11.5143 17.8285 11.5143C22.0999 11.5143 25.2571 10.0286 25.8142 8.54289V5.38574C24.1428 6.50003 21.3571 7.4286 18.0142 7.4286Z"
            fill="white"
          />
          <path
            d="M7.98572 14.4855C12.2572 14.4855 15.4143 12.9998 15.9714 11.5141C15.9714 9.84265 12.4429 8.35693 7.98572 8.35693C3.71429 8.54265 0 9.84265 0 11.5141C0.557143 13.1855 3.71429 14.4855 7.98572 14.4855Z"
            fill="white"
          />
          <path
            d="M7.98572 20.4285C12.2572 20.4285 15.4143 18.9428 15.9714 17.4571V14.1143C14.3 15.4143 11.5143 16.1571 7.98572 16.1571C4.45715 16.1571 1.85714 15.4143 0 14.1143V17.4571C0.557143 18.9428 3.71429 20.4285 7.98572 20.4285Z"
            fill="white"
          />
          <path
            d="M7.98572 22.1C4.64286 22.1 1.67143 21.3571 0 20.0571V23.4C0 24.8857 3.71429 26 7.98572 26C12.2572 26 15.9714 24.8857 15.9714 23.4V20.0571C14.3 21.1714 11.5143 22.1 7.98572 22.1Z"
            fill="white"
          />
        </svg>
        <p>已结算（元）</p>
      </div>
      <div class="price">{{ dData.total_price }}</div>

      <ul class="user_data">
        <li>
          <h2>今日收益（元）</h2>
          <p>{{ dData.today_price }}</p>
        </li>
        <li>
          <h2>今日邀请（人）</h2>
          <p>{{ dData.today_member }}</p>
        </li>
        <li>
          <h2>今日订单（单）</h2>
          <p>{{ dData.today_order }}</p>
        </li>
      </ul>

      <ul class="pc-btn">
        <li @click="$router.push({ name: 'Team2' })">
          <svg
            width="90"
            height="90"
            viewBox="0 0 90 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="45" cy="45" r="45" fill="url(#paint0_linear)" />
            <path
              d="M58.9872 50.0527C58.4645 49.4522 57.9419 48.8892 57.4223 48.3696C56.4113 47.3585 55.4624 46.5621 54.5882 45.9865C57.2201 43.1337 58.7041 39.332 58.7041 35.3686C58.7041 31.2713 57.1703 27.4167 54.386 24.5141C51.5922 21.6022 47.8745 20 43.9173 20C39.9601 20 36.2392 21.6022 33.4455 24.5141C30.6611 27.4136 29.1274 31.2682 29.1274 35.3686C29.1274 37.6147 29.5847 39.7831 30.49 41.8084C31.1838 43.3608 32.1047 44.7608 33.2402 45.9897C32.366 46.5652 31.4202 47.3616 30.4092 48.3696C29.0683 49.7074 27.6963 51.35 26.4395 53.1139C25.167 54.8997 24.0906 56.7072 23.3191 58.3436C22.4449 60.204 22 61.7626 22 62.9791C22 63.5111 22.4293 63.9404 22.9613 63.9404C23.3066 63.9404 40.9183 63.9777 53.2784 64.0057C52.1118 62.6804 51.1598 61.2929 50.7772 59.98C50.5438 59.1836 50.4505 58.3965 50.4505 57.6343C50.4505 51.9006 54.6317 48.5532 58.9872 50.0527Z"
              fill="white"
            />
            <path
              d="M64.5129 69.0457C65.7045 68.0564 71.6901 63.6481 72.8847 60.0953C73.115 59.414 73.1647 58.7264 73.1647 58.07C73.1647 51.5461 67.232 48.7555 62.6339 53.671C58.0513 48.7555 52.1123 51.7172 52.1123 58.07C52.1123 58.7264 52.1932 59.4077 52.3923 60.0953C53.4594 63.7414 59.5726 68.0533 60.7641 69.0457C61.181 69.3911 61.5699 69.7022 61.9152 69.9697C62.1236 70.1315 62.2761 70.2435 62.357 70.3026L62.637 70.5048L62.917 70.3026C62.9978 70.2435 63.1503 70.1315 63.3587 69.9697C63.7103 69.7022 64.096 69.3942 64.5129 69.0457Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="50.2941"
                y1="0"
                x2="50.2941"
                y2="95.2941"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F5686E" />
                <stop offset="1" stop-color="#FA5856" />
              </linearGradient>
            </defs>
          </svg>

          <p>我的团队</p>
        </li>
        <li @click="$router.push({ name: 'Income' })">
          <svg
            width="90"
            height="90"
            viewBox="0 0 90 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="45" cy="45" r="45" fill="url(#paint0_linear)" />
            <rect x="27" y="23" width="40" height="44" rx="4" fill="white" />
            <rect x="23" y="37" width="7" height="3" rx="1.5" fill="white" />
            <rect x="23" y="52" width="7" height="3" rx="1.5" fill="white" />
            <path
              d="M39.8291 35.436H42.4031L47.0051 43.964L51.6071 35.436H54.1811L48.8771 44.77H52.7511V46.07H48.1491V48.618H52.7511V49.918H48.1491V54H45.8611V49.918H41.2851V48.618H45.8611V46.07H41.2851V44.77H45.1331L39.8291 35.436Z"
              fill="#FB7E1C"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="50.2941"
                y1="0"
                x2="50.2941"
                y2="95.2941"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FB7E1C" />
                <stop offset="1" stop-color="#EB6D0B" />
              </linearGradient>
            </defs>
          </svg>

          <p>收益明细</p>
        </li>
        <li @click="$router.push({ name: 'Money' })">
          <svg
            width="90"
            height="90"
            viewBox="0 0 90 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="45" cy="45" r="45" fill="url(#paint0_linear)" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M41.583 30.3282H64.1959L60.5427 24.0007C59.4381 22.0875 56.9917 21.432 55.0786 22.5366L41.583 30.3282Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M26 31.7612C23.7909 31.7612 22 33.5521 22 35.7612V63.0112C22 65.2204 23.7909 67.0112 26 67.0112H65C67.2091 67.0112 69 65.2204 69 63.0112V53.3031H57.25C54.5461 53.3031 52.3542 51.1111 52.3542 48.4072C52.3542 45.7033 54.5461 43.5114 57.25 43.5114H69V35.7612C69 33.5521 67.2091 31.7612 65 31.7612H26Z"
              fill="white"
            />
            <circle cx="57.2503" cy="48.4071" r="1.95833" fill="white" />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="50.2941"
                y1="0"
                x2="50.2941"
                y2="95.2941"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FDAD39" />
                <stop offset="1" stop-color="#F89E35" />
              </linearGradient>
            </defs>
          </svg>

          <p>我的零钱</p>
        </li>
        <li @click="getPosters">
          <svg
            width="90"
            height="90"
            viewBox="0 0 90 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="45" cy="45" r="45" fill="url(#paint0_linear)" />
            <path
              d="M23 44.2549C23 42.7636 24.5733 41.7969 25.9037 42.4707L43.1926 51.2274C44.3288 51.8029 45.6712 51.8029 46.8074 51.2274L64.0963 42.4707C65.4267 41.7969 67 42.7636 67 44.2549V63C67 65.2091 65.2091 67 63 67H27C24.7909 67 23 65.2091 23 63V44.2549Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29 27C29 24.791 30.791 23 33 23H58C60.209 23 62 24.791 62 27V48C62 50.209 60.209 52 58 52H33C30.791 52 29 50.209 29 48V27ZM33 31C33 29.8955 33.8955 29 35 29H48C49.1045 29 50 29.8955 50 31C50 31.3193 49.9248 31.6211 49.792 31.8887C49.4648 32.5474 48.7852 33 48 33H35C33.8955 33 33 32.1045 33 31ZM34.5 38C33.6719 38 33 38.6714 33 39.5C33 40.3286 33.6719 41 34.5 41H55.5C55.9746 41 56.3984 40.7793 56.6738 40.4346C56.8779 40.1782 57 39.8535 57 39.5C57 38.6714 56.3281 38 55.5 38H34.5Z"
              fill="white"
              fill-opacity="0.8"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="50.2941"
                y1="0"
                x2="50.2941"
                y2="95.2941"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#DFA275" />
                <stop offset="1" stop-color="#C48F69" />
              </linearGradient>
            </defs>
          </svg>

          <p>邀请好友</p>
        </li>
      </ul>
    </div>

    <Overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="erweima_wrapper">
          <img
            class="erweima_img"
            :src="haibao_img"
            alt=""
            @click="getPosters"
          />
          <p>长按保存图片-点击海报可切换</p>
        </div>
      </div>
    </Overlay>
  </div>
</template>

<script>
import { post } from "@get/http";
import { Overlay, Toast } from "vant";
export default {
  components: {
    Overlay,
  },
  data() {
    return {
      haibao_img: "", //当前海报
      show: false, //海报是否展示
      hb_get_thisHB: 0, //当前海报序列号
      hb_data: [], //海报配置
      dData: {
        total_price: 0,
        today_price: 0,
        today_member: 0,
        today_order: 0,
      },
    };
  },
  methods: {
    async onLoad() {
      // 自启动
      try {
        //获得所有书生海报的配置
        let { data } = await post("/Posters/index", {
          data: {
            page: 1,
          },
        });
        this.hb_data = data;
      } catch (error) {
        console.error(error);
      }
    },
    async getPosters() {
      // 获取书生海报
      Toast.loading({
        message: "加载中...",
        loadingType: "spinner",
        overlay: true,
        forbidClick: true,
        duration: 0,
      });
      if (this.hb_get_thisHB == this.hb_data.length) {
        this.hb_get_thisHB = 0;
      }
      let { posters_image } = await post("/Member/getPosters", {
        data: {
          posters_config_id: this.hb_data[this.hb_get_thisHB].id,
        },
      });
      this.show = true;
      this.haibao_img = env.SITE_URL + posters_image;
      this.hb_get_thisHB++;
      Toast.clear();
    },
  },
  async created() {
    this.onLoad();

    let data = await post("/lw.MemberOrderInviter/todayData");
    this.dData = data;
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.promotionCenter {
  width: rem(750);
  margin: 0 auto;
  background: url("~@image/me/promotionCenter_bg.jpg") no-repeat #f8f8f8;
  background-size: rem(750) rem(321);
}

.pc-block {
  padding-top: rem(56);
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(16);
    > svg {
      width: rem(26);
      height: rem(26);
    }
    > p {
      margin-left: rem(6);
      font-size: rem(28);
      color: #ffffff;
    }
  }
  > .price {
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    font-size: rem(56);
    margin-bottom: rem(60);
  }

  .user_data {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    padding: 0 rem(26);
    box-sizing: border-box;
    background: #ffffff;
    width: rem(690);
    height: rem(142);
    box-shadow: 0px rem(4) rem(10) rgba(0, 0, 0, 0.09);
    border-radius: rem(16);
    li {
      h2 {
        color: #666666;
        font-size: rem(24);
      }
      p {
        margin-top: rem(10);
        text-align: center;
        font-size: rem(30);
        color: #222222;
      }
    }
  }
  .pc-btn {
    display: flex;
    box-sizing: border-box;
    padding: 0 rem(50);
    margin-top: rem(56);
    justify-content: space-between;
    li {
      svg {
        width: rem(90);
        height: rem(90);
      }
      p {
        color: #000000;
        font-size: rem(26);
        text-align: center;
      }
    }
  }
}

.wrapper {
  position: relative;
}
.erweima_wrapper {
  // margin:  1rem auto 0 ;
  top: 1rem;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  width: 70%;
  background: #fff;
  position: absolute;
  p {
    text-align: center;
    padding: 0.2rem 0;
  }

  .erweima_img {
    display: block;
    width: 100%;
  }
}
</style>
